import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroText from "../components/heroText"

const NotFoundPage = () => (
  <Layout>
    <SEO title="Not Found" />
    <HeroText
      statement="Not Found"
      caption="The page you have requested could not be found."
    ></HeroText>
  </Layout>
)

export default NotFoundPage
